<template>
	<div class="soundboard-parent"></div>
</template>

<script>
	export default {
		// render: () => null,

		//------------------------------------------------------------
		// CREATED
		//------------------------------------------------------------

		created() {
			// console.log('Soundboard ACTIVE');
		},

		beforeDestroy() {

		}
	}
</script>